/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  imageSq,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
            image
            fbApp
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogImage = image.replace(/undefined/i, "") || site.siteMetadata.image
  const ogImageSq =
    imageSq.replace(/undefined/i, "") || site.siteMetadata.imageSq
  const fbAppId = site.siteMetadata.fbApp
  const siteUrl = site.siteMetadata.siteUrl
  const path_Slug = globalHistory.location.pathname
  const BASE_URL = siteUrl
  const path = BASE_URL + path_Slug

  //console.log("path is: " + BASE_URL + " image is " + ogImage)
  //console.log("canonical: " + path)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={BASE_URL + ogImage} />
      <link rel="canonical" href={path} />

      {/* OpenGraph tags */}
      <meta property="og:locale" content="en_EN" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={path} />
      <meta property="og:site_name" content="Velvetzoo" />
      <meta property="og:image" content={BASE_URL + ogImage} />
      <meta property="og:image:secure_url" content={BASE_URL + ogImage} />
      <meta property="og:image" content={BASE_URL + ogImageSq} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="531" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:alt" content={title} />
      <meta property="fb:app_id" content={fbAppId} />
      {/*Twitter Card*/}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={BASE_URL + ogImage} />
      <meta name="distribution" content="global" />
      <meta name="robots" content="index,follow" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  image: ``,
  imageSq: ``,
  pathname: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageSq: PropTypes.string,
  pathname: PropTypes.string,
}

export default SEO
